import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Chip, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { FixedSizeList } from 'react-window'; // For virtualization
import * as utils from './utils'

const ModelList = ({ modelOptions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLibrary, setSelectedLibrary] = useState('');
  const [listHeight, setListHeight] = useState(400); // Default height

  // Library options for chips
  const libaryOptions = [
    { name: "timm", display_name: "timm" },
    { name: "diffusers", display_name: utils.diffusers_str_w_emoji },
    { name: "transformers", display_name: utils.transformers_str_w_emoji }
  ];

  // Filter models by search term and selected library
  const filteredModels = modelOptions.filter((model) => {
    const matchesSearch = model.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLibrary = selectedLibrary === '' || model.library === selectedLibrary;
    return matchesSearch && matchesLibrary;
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLibraryClick = (library) => {
    setSelectedLibrary(library === selectedLibrary ? '' : library); // Toggle library selection
  };

  // Row rendering function for react-window
  const renderRow = ({ index, style }) => {
    const item = filteredModels[index];
    const handleClick = () => {
      window.open(`models/?model=${item.name}`, '_blank', 'noopener,noreferrer');
    };

    return (
      <Grid item xs={12} key={index} style={style}>
        <Card 
          sx={{ 
            display: 'flex', 
            width: '100%', 
            cursor: 'pointer', 
            transition: 'background-color 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }
          }} 
          onClick={handleClick}
        >
          <CardContent sx={{ flexBasis: '220px', flexShrink: 0 }}>
            <Typography variant="h6">{item.name}</Typography>
            <Typography variant="body2" color="textSecondary">{item.library}</Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{
              flexGrow: 1,
              objectFit: 'cover',
              objectPosition: 'left'
            }}
            image={`${process.env.PUBLIC_URL}/data/model_thumbnails/darkspark_thumbnail_${item.name}.png`} 
            alt={item.name}
          />
        </Card>
      </Grid>
    );
  };

  useEffect(() => {
    const calculateListHeight = () => {
      const availableHeight = window.innerHeight - 380;
      setListHeight(availableHeight);
    };
    calculateListHeight();
    window.addEventListener('resize', calculateListHeight);
    return () => window.removeEventListener('resize', calculateListHeight);
  }, []);

  return (
    <Box sx={{ backgroundColor: '#e0e0e0', padding: '20px', borderRadius: '8px', height: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Explore pre-traced models
      </Typography>

      {/* Container for search bar and chips */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
        {/* Search Field */}
        <TextField
          label="Search models"
          variant="outlined"
          sx={{ width: '400px' }} // Fixed width for search bar
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* Library Chips to the right of the search bar */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          {libaryOptions.map((library) => (
            <Chip
              key={library.display_name}
              label={library.display_name}
              clickable
              color={selectedLibrary === library.display_name ? 'primary' : 'default'}
              onClick={() => handleLibraryClick(library.display_name)}
            />
          ))}
        </Box>
      </Box>

      {/* Virtualized List */}
      <Box sx={{ height: `${listHeight}px`, backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
        <FixedSizeList
          height={listHeight}
          width="100%"
          itemSize={140}
          itemCount={filteredModels.length}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </Box>
  );
};

export default ModelList;
