import * as THREE from 'three';



///////////////////////////////
// globals
///////////////////////////////

export let globals = {
    max_depth: undefined,
    max_depth_visible: undefined,
    max_n_params_visible:undefined,
    curves_lookup: {},
    nodes_lookup: {},

    ops_of_visible_nodes: [], // convenience, for drawing and updating labels
    ops_of_visible_planes: [],
    camera: undefined,
    nn: undefined,
    mount: undefined,
    DEBUG:false,
    SHOW_ACTIVATION_VOLUMES:true,
    is_tweening:false,
    COLLAPSE_ALL_RESHAPE_MODULES:true,
    scene_bb: {x_max:0, x_min:0, y_max:0, y_min:0},
    act_cube_size: 0.02,
    nodes_color_by:"none",
    currentHighestWindowZ:100 // draggable windows
}

export let scene = new THREE.Scene();
scene.background = new THREE.Color(...[248, 249, 250].map(d => d/255));
globals.scene = scene
