import { globals } from './globals';
import * as utils from './utils';
import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

///////////////////////////////
// Label visibility
///////////////////////////////

export function populate_labels_pool() {
    globals.labels_pool = []
    globals.all_labels = []
    let N_LABELS_IN_POOL = 400
    for (let i=0; i<N_LABELS_IN_POOL; i++) {
    
        const div = document.createElement( 'div' );
        div.className = 'label';    
        div.style.backgroundColor = 'transparent';

        for (let i=0; i<8; i++) {
            let span = document.createElement('span')
            div.appendChild(span)
        }
    
        const label = new CSS2DObject( div );
        // label.frustumCulled = false

        // label.element.style.display = "none" // this wasn't doing it, have to set label.visible
        label.visible = false
    
        label.position.set( Math.random(),0,Math.random()-2 );
        
        label.center.set( .5, 1.1 ); // above node, centered horizontally
        let label_id = i
        label.label_id = label_id

        globals.scene.add(label)
    
        globals.labels_pool.push(label)
        globals.all_labels.push(label)
    }
}

export const dim_color_lookup = {
    "unknown": "grey",
    "features": "rgb(30, 140, 100)",
    "spatial":"rgb(20, 30, 180)",
    // "batch":"purple"
    "batch":"grey"
}
// grab label from pool, fill it out w op's info and position it at op's location
function assign_label_to_op(op) {
    let label = globals.labels_pool.pop()

    if (label) {
        // label.position.set(op.x, 0, op.y)
        label.position.set(0, 0, 0) 
        // note there are two ways to set position: center.set works in screen space, position.set works in scene space. We first align how
        // we want in px space, then set in world space so stays nice through zooms. Eg for nodes align to bottom of text, then place that bottom
        // of text directly above the sphere in scene coords
        op.mesh.add(label)

        label.center.set( .5, 0); // centered horizontally w node. Screen coords

        ////
        if (["mod_out", "fn_out"].includes(op.node_type) || op.is_global_input){
            label.element.style["font-size"] = "11px"
            if (op.tensor_is_expanded) {
                let spans = op.expanded_tensor_spans
                if (op.tensor_node_display_type==="grid") { // wtf why actgrid use y instead of z? rotation something somewhere, can standardize TODO
                    label.position.x -= (spans.x_span/2 + .22) // eyeballed extra for margins
                    label.position.y -= (spans.y_span_half+.24) // actgrid spanhalf is actually full span...     
                    // label.position.z += 1 //spans.y_span_half     
                } else {
                    label.position.x -= (spans.x_span/2)
                    label.position.z += spans.y_span_half
                }

            } else {
                label.position.z += .05
            }
            if ("dim_types" in op) {
                let spans = label.element.children

                let spans_ix = 0
                spans[0].innerText = "("; spans_ix += 1
                spans[0].style.display = 'inline'
                op.shape.forEach((s,i) => {
                    let dim_type = op.dim_types[i]
                    let color = dim_color_lookup[dim_type]
    
                    let span = spans[spans_ix]; spans_ix+= 1
                    span.style.display = 'inline'
                    span.style.color = color;
                    if (i < (op.shape.length-1)) s += ', '
                    span.innerText = s; 
                })
                let last_span = spans[spans_ix]

                last_span.innerText = ")"
                last_span.style.display = "inline"

            } else {
                let spans = label.element.children
                spans[0].innerText = "("+op.shape+")"
                spans[0].style.display = 'inline'
                spans[0].style.color = dim_color_lookup["unknown"];
            }
        } else if (["function", "module"].includes(op.node_type)) {
            label.element.style["font-size"] = "12px"
            label.center.set( .5, 1); // centered horizontally w node, aligns w bottom of text to node center vertically
            label.position.z -= .08 // scene coords
            let spans = label.element.children
            let first_span = spans[0]
            first_span.style.display = 'inline'
            first_span.style.color = 'rgb(50,60,60)'

            if (op.name=="reshape*") { // special reshape group
                const icon = document.createElement('i');
                icon.className = "fa-solid fa-shuffle"
                icon.style.color = "grey"
                first_span.appendChild(icon) // TODO we need to be deleting this now also
            } else { // standard node

                // first_span.innerText = op.name.slice(0, 10)
                let text = formatText(op.name)
                if ("fn_metadata" in op) {
                    if ("kernel_size" in op.fn_metadata) {
                        let k = op.fn_metadata.kernel_size
                        k = k.includes(",") ? k : "("+k+"x"+k+")"
                        k = k.replace(", ", "x")
                        text += " "+k
                    }
                    if ("groups" in op.fn_metadata) {
                        if (parseInt(op.fn_metadata.groups)>1) {
                            text += "<br>groups: "+op.fn_metadata.groups
                        }
                    }
                }
                
                if (("action_along_dim_type" in op) && (op.action_along_dim_type!=="unknown")) {
                    text += ("<br>("+op.action_along_dim_type+")")
                }

                first_span.innerHTML = text

            }

        } 
        ///////
        
        label.visible = true
        label.element.style.visibility = 'visible'
        op.active_node_label = label
        label.current_op = op
    } else {
      console.log("label pool empty")
    }
}
function is_uc(char) {
    return char===char.toUpperCase()
}
function is_lc(char) {
    return char===char.toLowerCase()
}
function formatText(text) { // chatGPT
    let formattedText = '';
    let start = 0;

    // Helper function to find the natural breakpoint
    function findNaturalBreakpoint(str, start, maxLen) {
        let breakpoints = []
        for (let i = start + 1; i <= start + maxLen; i++) {
            if (
                str[i] === '_' || 
                (str[i+1] && is_uc(str[i]) && is_lc(str[i+1])) ||
                (!isNaN(str[i - 1]) && isNaN(str[i]))
                ) {
                // return i;
                breakpoints.push(i)
            }
        }
        if (breakpoints.length>0) {
            return breakpoints[breakpoints.length-1]
        } else {
            return start + maxLen; // Default to the max length if no breakpoint is found
        }
    }

    while (start < text.length) {
        if (text.length - start <= 14) {
            // If the remaining text is less than or equal to 12, just append it
            formattedText += text.slice(start);
            break;
        } else {
            // Find a natural breakpoint between the 8th and 12th character
            let breakPoint = findNaturalBreakpoint(text, start, 14);
            formattedText += text.slice(start, breakPoint) + '<br>';
            start = breakPoint;
        }
    }

    return formattedText;
}

export function remove_label_from_op_and_return_to_pool(op) {
    if (op.active_node_label != undefined) {
        let label = op.active_node_label
        op.mesh.remove(label) // remove from three.js Group
    
        // label.element.style.display = 'none' // doesn't work, that wasted an hour
        label.visible = false // i think this overrides manually setting it. Have to do it this way. 
        label.element.style.opacity = 1

        // set all spans as display none. Can use the display==none technique here, though not on the style of the base div element (that is overridden by label.visible)
        let spans = label.element.children
        for (let i=0; i<spans.length; i++) {
            let span = spans[i]
            span.style.display = 'none'
            span.style.color = 'black'
            span.innerText = ""
        }
    
        globals.labels_pool.push(label)
        op.active_node_label = undefined
        label.current_op = undefined
    }
}

let interesting_ops = ["matmul", "cat"]
function is_interesting_op(op) {
    return (
        op.n_params>0 ||
        interesting_ops.includes(op.name)
        )
}

export function update_onscreen_status() {
    //////////////////////////
    // Mark on-screen status
    let [h_width, h_height, cx, cz] = utils.get_main_window_position()
    let bh = 3; let bv = 1.5 // scaling to give buffer to count as 'on screen' to put labels in place before they scroll into view.
    let screen_left = cx-h_width*bh; let screen_right = cx+h_width*bh; let screen_top = cz+h_height*bv; let screen_bottom = cz-h_height*bv
    globals.ops_of_visible_nodes.forEach(op => {
      let is_onscreen = (op.x > screen_left) && (op.x < screen_right) && (op.y>screen_bottom) && (op.y<screen_top)
      op.is_onscreen = is_onscreen
    })
}

function update_nodes_labels() {
    console.log("update nodes labels")
    update_onscreen_status()
    globals.ops_of_visible_nodes.forEach(op => {
      let is_onscreen = op.is_onscreen
      let zoomed_enough = (globals.camera.zoom > 30 || 
                            (globals.camera.zoom > 26 && op.tensor_is_expanded) || // actgrids and actvols
                            (globals.camera.zoom > 15 && is_interesting_op(op))
                            )
      if (is_onscreen && zoomed_enough && op.should_draw) {
        if (!op.active_node_label) {
            assign_label_to_op(op)
        }
      } else {
        remove_label_from_op_and_return_to_pool(op)
      }
    })

    // only needed after collapse or expansion, not during panning. Could be separate fn but shouldn't be expensive bc not too many in all_labels
    globals.all_labels.forEach(label => {
        if (label.current_op && !label.current_op.is_currently_visible_node) {
            remove_label_from_op_and_return_to_pool(label.current_op)
        }
    })

    // 
    hide_overlapping_labels()
  }


function hide_overlapping_labels() {
    /*
    this part is not streamlined. We're using two approaches. Nodes use labels pool. Planes get their own labels which the keep all the time.
    planes labels use the overlap detection below, which works for them reliably now. Nodes use the div.getBoundingBox approach, also below. 
    We're first checking for overlap within planes labels using their apparatus, then using the planes labels to hide overlapping nodes labels,
    then comparing nodes labels w eachother. Ideally we'd have one labels pool, sort it by priority, cycle through from each side always taking the
    higher priority one. Not for perfs sake, but for sanity as we're now maintaining two ways of getting labels, and two ways of detecting overlap.
    */
    let nodes_labels = globals.all_labels.filter(l => l.current_op)

    let planes_labels = globals.ops_of_visible_planes.filter(op => {
        let show_plane_label = (globals.camera.zoom > 60) || 
            (globals.camera.zoom > 30 && op.n_ops > 6) || 
            (globals.camera.zoom > 20 && op.n_ops > 12) || 
            (globals.camera.zoom > 7 && op.n_ops > 24)
        return show_plane_label
    }).map(op => op.expanded_plane_label)

    let active_labels = nodes_labels//.concat(planes_labels)
    
    // Reset all labels to be visible initially
    active_labels.forEach(l => {
        // l.element.style.visibility = 'visible'
        l.visible = true
        l.is_hidden = false
        l.element.style.opacity = 1
    })
    planes_labels.forEach(l => {
        // l.element.style.visibility = 'visible'
        // l.visible = true // can't do this, they were just marked for overlap TODO consolidate the flags here, just use is_hidden and opacity,
        // don't use visible anymore
        l.is_hidden = false
        l.element.style.opacity = 1
    })

    let to_hide = []

    // Check for overlap w plane labels and node labels
    for (let i = 0; i < nodes_labels.length; i++) {
        for (let j = 0; j < planes_labels.length; j++) {
            let l1 = nodes_labels[i]
            let l2 = planes_labels[j]
            if (l2.visible) { // if plane is visible
                if (doDivsIntersect(l1.element, l2.element)) { // if overlap, hide node label
                    // l1.element.style.visibility = 'hidden'
                    l1.is_hidden = true
                    to_hide.push(l1)
                } 
            }
        }
    }
    
    // Iterate through the labelRects to check for overlaps
    // active_labels.sort((a,b)=>a.current_op.n_params - b.current_op.n_params)
    // more params gets precedence
    active_labels.sort((a, b) => {
        const aParams = a.current_op?.n_params ?? -Infinity; // Treat undefined as a very small value
        const bParams = b.current_op?.n_params ?? -Infinity; // Treat undefined as a very small value
    
        return aParams - bParams;
    });
    // TODO should also sort by sparkflow to decide overlap btwn tensor nodes
    
    for (let i = 0; i < active_labels.length; i++) {
        // for (let j = i + 1; j < active_labels.length; j++) {
        for (let j = active_labels.length-1; j > i; j--) {
            let l1 = active_labels[i]
            let l2 = active_labels[j]
            if (!l1.is_hidden && !l2.is_hidden) { // if both are still visible
                if (doDivsIntersect(l1.element, l2.element)) { // if overlap, hide one
                    // l1.element.style.visibility = 'hidden'
                    to_hide.push(l1)
                    l1.is_hidden = true
                } 
            }
        }
    }
    to_hide.forEach(label => {
        label.element.style.opacity = 0 //.3
        // label.visible = false

    })
}

function doDivsIntersect(div1, div2) {
    // Get bounding rectangles of both divs
    const rect1 = div1.getBoundingClientRect();
    const rect2 = div2.getBoundingClientRect();

    // Check if the rectangles overlap
    const overlap = !(
        rect1.right < rect2.left ||   // rect1 is to the left of rect2
        rect1.left > rect2.right ||   // rect1 is to the right of rect2
        rect1.bottom < rect2.top ||   // rect1 is above rect2
        rect1.top > rect2.bottom      // rect1 is below rect2
    );

    return overlap;
}
    
// from chatgpt
const getScreenCoordinates = (object) => {
    const vector = new THREE.Vector3();
    object.getWorldPosition(vector);
    vector.project(globals.camera);

    const x = (vector.x * 0.5 + 0.5) * globals.mount.clientWidth;
    const y = (vector.y * -0.5 + 0.5) * globals.mount.clientHeight;
    return { x, y, v:vector };
};

// Function to calculate bounding box of a label
const calculateBoundingBox = (label, coords) => {
    const padding = 6 //2; // Small padding value to ensure overlap detection accuracy
    const width = label.element.offsetWidth + padding;
    const height = 8 //label.element.offsetHeight + padding;
    // Adjust x and y to represent the center position
    const x = coords.x - width / 2;
    const y = coords.y - height / 2;
    return { x, y, width, height, label };
};

const checkOverlap = (rect1, rect2) => {
    return (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
    );
};

const low_priority_names = ["Sequential"] // will be removed first when label collisions happen

function update_planes_labels() { 

    // Only consider if within distance
    // TODO only do if on screen
    let consider_drawing = []
    globals.ops_of_visible_planes.forEach(op => {
        if (globals.camera.zoom > 60 || 
            globals.camera.zoom > 30 && op.n_ops > 6 || 
            globals.camera.zoom > 20 && op.n_ops > 12 || 
            globals.camera.zoom > 7 && op.n_ops > 24) {
            consider_drawing.push(op)
        } else {
            // op.expanded_plane_label.element.style.display = 'none';
            op.expanded_plane_label.visible = false;
        }
    })

    // Of those within distance, remove if overlap
    // Reset all labels to be visible initially
    consider_drawing.forEach(op => {
        // op.expanded_plane_label.element.style.display = 'block'; // doesn't work, have to set visible
        op.expanded_plane_label.visible = true;
        op.expanded_plane_label.element.style.opacity = 1
    });
    
    const labelRects = consider_drawing.map(op => {
        const coords = getScreenCoordinates(op.expanded_plane_label);
        let bb = calculateBoundingBox(op.expanded_plane_label, coords);
        bb.name = op.name
        return bb
    });
    
    // Iterate through the labelRects to check for overlaps
    for (let i = 0; i < labelRects.length; i++) {
        for (let j = i + 1; j < labelRects.length; j++) {
            const rect1 = labelRects[i];
            const rect2 = labelRects[j];
            if (checkOverlap(rect1, rect2)) {
                let to_hide = low_priority_names.includes(rect1.name) ? rect1 : rect2
                // to_hide.label.element.style.display = 'none'; // Hide the overlapping label
                to_hide.label.visible = false; // Hide the overlapping label
            }
        }
    }

}

export function update_labels() {
    update_planes_labels()

    update_nodes_labels()
}