import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Menu, MenuItem } from '@mui/material';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import * as TWEEN from '@tweenjs/tween.js';

import { globals } from './globals';
import recompute_layout from './layout_engine';
import { draw_nn } from './draw_nn';
import * as utils from './utils'
import * as label_utils from './label_utils'

import { CLICKABLE_LAYER } from './utils';
import pako from 'pako';
import Stats from 'three/addons/libs/stats.module.js';
import DraggableWindow from './DraggableWindow';
import { Image, Camera } from 'lucide-react';

let scene = globals.scene

// minimap window
const minimap_geometry = new THREE.PlaneGeometry(1, 1, 1, 1)
const minimap_material = new THREE.MeshBasicMaterial({
  color: new THREE.Color("grey"),
  transparent: true,
  opacity: 0.4
});
let minimap_window = new THREE.Mesh(minimap_geometry, minimap_material);
minimap_window.rotation.x = -Math.PI/2
let minimap_window_is_dragging = false

globals.minimap_window_plane = minimap_window

const MINIMAP_CAMERA_HEIGHT = 110
const MAIN_CAMERA_HEIGHT = 100
let minimap_camera, minimap_mount
let camera, mount
let inset_camera, inset_mount
let INTERSECTED, controls, labelRenderer;

function update_main_camera_position(cx, cz) {
  camera.position.x = cx; controls.target.x = cx // need to update both otherwise camera rotates
  camera.position.z = cz; controls.target.z = cz
}

let drag_controls
let pointer = new THREE.Vector2();
let raycaster = new THREE.Raycaster(); 

const renderer = new THREE.WebGLRenderer({ antialias: true });
const inset_renderer = new THREE.WebGLRenderer({ antialias: true });
const minimap_renderer = new THREE.WebGLRenderer({ antialias: true });

//
let minimap_total_height = 120
let minimap_scrollbar_height = 6 // does not include outline

let camera_pos_x
let camera_pos_y
let camera_zoom

let orbit_controls_is_active = false
let currentMouseCoords = { x: 0, y: 0 };
let mouse_is_down = false
let rightClickStartTime = 0;
let rightClickMouseLocation = {x:0, y:0}

let hovered_op = null

const MainPanel = ({ filters, setDropdownValue, setDepthValues, setOverviewStats, setHelpInformation, 
                      setActsDisplayStatus, setActsDisplayOptions,
                      setIsThinking,
                     }) => {
  const mountRef = useRef(null);
  const statsRef = useRef(null);
  const minimapMountRef = useRef(null);
  // const insetMountRef = useRef(null); NOTE will bring back in w microscope
  const [tooltipObject, setTooltipObject] = useState(null);
  const [featureTooltipObject, setFeatureTooltipObject] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [contextMenu, setContextMenu] = useState(null)
  const [tracedImgsList, setTracedImgsList] = useState(["one", "two", "three"]);
  const [inputImage, setInputImage] = useState(null);
  const [activationsShowing, setActivationsShowing] = useState(false);
  globals.setInputImage = setInputImage
  globals.setActivationsShowing = setActivationsShowing
  globals.setActsDisplayOptions = setActsDisplayOptions
  globals.setTracedImgsList = setTracedImgsList
  
  globals.setIsThinking = setIsThinking
  globals.setHelpInformation = setHelpInformation


  const [minimap_scrollbar_pos, setMinimapScrollbarPos] = useState({'left_perc':0, 'width_perc':0, 'display':'none', 'minimap_height':minimap_total_height});

  // remember this part of the code gets executed all the time. For one-time things on init, put in useEffect below

  ///////////////////////////////////////
  // Initialize scene once on page load
  ////////////////////////////////////

  useEffect(() => {

    // main mount
    globals.mount = mountRef.current;
    mount = globals.mount
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    mount.appendChild(renderer.domElement);

    globals.camera = new THREE.OrthographicCamera(
      mount.clientWidth / -2, mount.clientWidth / 2,
      mount.clientHeight / 2, mount.clientHeight / -2,
      0.1, 1000
    );
    camera = globals.camera
    
    // minimap mount
    minimap_mount = minimapMountRef.current
    minimap_renderer.setSize(minimap_mount.clientWidth, minimap_mount.clientHeight);
    minimap_mount.appendChild(minimap_renderer.domElement)

    minimap_camera = new THREE.OrthographicCamera( // same settings as above
      minimap_mount.clientWidth / -2, minimap_mount.clientWidth / 2,
      minimap_mount.clientHeight / 2, minimap_mount.clientHeight / -2,
      0.1, 1000
    );
    // Enable both default layer and clickable layer on the camera
    minimap_camera.layers.enable(0); // Default layer
    minimap_camera.layers.enable(utils.LINE_OBJECTS_LAYER);
    minimap_camera.layers.enable(utils.ACTVOL_OBJECTS_LAYER);
    minimap_camera.layers.enable(utils.OP_NODES_OBJECTS_LAYER);


    minimap_camera.position.set(0, MINIMAP_CAMERA_HEIGHT, 0 );
    minimap_camera.zoom = 10 // for 2d
    minimap_camera.lookAt(0, 0, 0); // this is needed prob bc no orbitcontrols, so point camera at origin

    // //////////////////////// NOTE this fn works, but disabling for v0, bring back in w microscope
    // // inset camera
    // inset_mount = insetMountRef.current
    // inset_renderer.setSize(inset_mount.clientWidth, inset_mount.clientHeight);
    // inset_mount.appendChild(inset_renderer.domElement)
    // inset_camera = new THREE.OrthographicCamera( // same settings as above
    //   inset_mount.clientWidth / -2, inset_mount.clientWidth / 2,
    //   inset_mount.clientHeight / 2, inset_mount.clientHeight / -2,
    //   0.1, 1000
    // );
    // inset_camera.layers.enable(utils.MINIMAP_OBJECTS_LAYER);
    // inset_camera.layers.enable(utils.ACTVOL_OBJECTS_LAYER);
    // inset_camera.layers.enable(utils.OP_NODES_OBJECTS_LAYER);
    // inset_camera.layers.enable(0); // Default layer
    // inset_camera.layers.enable(CLICKABLE_LAYER);

    // inset_camera.position.set(0, MAIN_CAMERA_HEIGHT, 0 ); // height doesn't matter really
    // inset_camera.zoom = 5
    // inset_camera.updateProjectionMatrix(); // otherwise zoom doesn't update, though position does

    // inset_camera.lookAt(0, 0, 0); // this is needed prob bc no orbitcontrols, so point camera at origin
    // ////////////////////////


    // Enable both default layer and clickable layer on the camera
    camera.layers.enable(0); // Default layer
    camera.layers.enable(CLICKABLE_LAYER);
    camera.layers.enable(utils.LINE_OBJECTS_LAYER);
    camera.layers.enable(utils.ACTVOL_OBJECTS_LAYER);
    camera.layers.enable(utils.OP_NODES_OBJECTS_LAYER);
    camera.layers.enable(utils.TENSOR_NODES_OBJECTS_LAYER);
    camera.position.set(0, MAIN_CAMERA_HEIGHT, 0 );
    camera.zoom = 28 // for 2d

    // window.addEventListener( 'resize', onWindowResize );
    window.addEventListener( 'dblclick', onPointerDown );
    // window.addEventListener( 'click', singleClick );
    window.addEventListener('mousemove', onMouseMove, false);
    
    // // Add an event listener for the 'keydown' event
    // window.addEventListener('keydown', function(event) {
    //   // Check if the 'Ctrl' key is pressed and the 'D' key is pressed
    //   if (event.ctrlKey && event.key === 'd') {
    //       event.preventDefault(); // Optional: Prevent the default action (e.g., bookmark shortcut)
    //       console.log('Ctrl+D was pressed!');
    //       utils.update_labels()
    //   }
    // });


    // Add an event listener for the 'keydown' event
    window.addEventListener('keydown', function(event) {
      // Check if the 'Ctrl' key is pressed and the 'D' key is pressed
      if (event.ctrlKey && event.key === 'd') {
          event.preventDefault(); // Optional: Prevent the default action (e.g., bookmark shortcut)
          console.log('Ctrl+d was pressed!');
          utils.save_current_state()
      }

      // Check if the 'Ctrl' key is pressed and the 'D' key is pressed
      if (event.ctrlKey && event.key === 'm') {
          event.preventDefault(); // Optional: Prevent the default action (e.g., bookmark shortcut)
          console.log('Ctrl+m was pressed!');
          utils.saveMinimapAsImage(minimap_renderer, minimap_camera)
      }
    
    });
    ////

    // Listener for mousedown, specifically for right-click (button === 2)
    window.addEventListener('mousedown', function (event) {
        if (event.button === 2) { // Right-click
            rightClickStartTime = Date.now(); // Record the time when mousedown occurs
            rightClickMouseLocation = {x:event.clientX, y:event.clientY}
        }
    });


    
    // Listener for mouseup, specifically for right-click (button === 2)
    window.addEventListener('mouseup', function (event) {
        if (event.button === 2) { // Right-click
            const rightClickEndTime = Date.now(); // Record the time when mouseup occurs
            const elapsedTime = rightClickEndTime - rightClickStartTime; // Calculate the elapsed time
            let elapsedDist = Math.sqrt((rightClickMouseLocation.x - event.clientX)**2 + (rightClickMouseLocation.y - event.clientY)**2)
            if ((elapsedTime < 200) && (elapsedDist<3) && ((hovered_op && hovered_op.name!=="Root") || event.shiftKey)) {
            // if (event.shiftKey) { // i think the above is ok, just testing
                console.log("hovered_op", hovered_op)
                setContextMenu(
                  contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, "current_op":hovered_op } : null
                )
            }
        }
    });
    


    // Label renderer
    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize( mount.clientWidth, mount.clientHeight );
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    mount.appendChild( labelRenderer.domElement );


    // controls
    controls = new OrbitControls( camera, labelRenderer.domElement );
    // note this is added to labelrenderer dom, otherwise can't use it. If no labelrendered, use renderer dom
    controls.enableRotate = false; // Disable rotation
    controls.screenSpacePanning = true; // Allow panning in screen space
    
    controls.addEventListener( 'start', function ( event ) {
      orbit_controls_is_active = true
    } );
    
    // update labels after controls moves
    controls.addEventListener( 'end', function ( event ) {
      orbit_controls_is_active = false

      label_utils.update_labels()
    } );

    // // middle btn pan, like blender and apparently adobe suite. Zoom still works as expected. I don't like this bc btn harder to press
    // right click is easier. Though maybe i'm just used to it?
    // controls.mouseButtons = {
    //   LEFT: null,   // Rotate with left mouse button
    //   MIDDLE: THREE.MOUSE.PAN,    // Pan with middle mouse button (scroll wheel click)
    //   RIGHT: null                 // Disable right mouse button
    // };

    /////
    drag_controls = new DragControls( [ minimap_window ], minimap_camera, minimap_renderer.domElement );
    drag_controls.addEventListener( 'dragstart', function ( event ) {
      console.log("minimap window dragstart")
      minimap_window_is_dragging = true
    } );
    // drag_controls.addEventListener( 'drag', function ( event ) {
    //   console.log("minimap window dragging")
    //   // minimap_window_is_dragging = false
    //   utils.update_labels()
    // } ); 
    // this makes janky, but if don't have then on dragend labels don't update. same as issue where we have to update 
    // before tween as well as after TODO

    drag_controls.addEventListener( 'dragend', function ( event ) {
      console.log("minimap window dragend")
      minimap_window_is_dragging = false
      label_utils.update_labels()
    } );

    // drag_controls.getRaycaster().layers.set(utils.MINIMAP_OBJECTS_LAYER) TODO wth?


    // Set up stats
    const stats = new Stats();
    stats.showPanel(0); // Show FPS panel
    if (true) { //(globals.DEBUG) { // this could be cleaner. Just not appending if not in debug mode.
      statsRef.current.appendChild(stats.dom);
    }

    
    //////////////////////////////////////////////////
    // Minimap
    //////////////////////////////////////////////////


    function render_minimap() {
        let plane = globals.nn.expanded_plane_mesh
        const boundingBox = new THREE.Box3().setFromObject(plane);

        // padding in two places here, i don't understand why can't just have it in first place (here)
        // NOTE happens bc actvols extend out of root plane. Was especially egregious in autoencoderkl, values 
        // largely fit to that model
        // this whole padding logic prob redo, brain fuzzy right now
        let pad_x = 5 // in scene coords
        let pad_y = 2
        boundingBox.max.x += pad_x
        boundingBox.min.x -= pad_x
        // boundingBox.max.z += pad_y
        boundingBox.min.z -= pad_y

        let scene_h_width = (boundingBox.max.x - boundingBox.min.x) / 2 // for the entire scene, not the view window
        let scene_h_height = (boundingBox.max.z - boundingBox.min.z) / 2

        // zoom
        let zx = minimap_camera.right / scene_h_width // zoom to make right and left line up
        let MIN_ZOOM = 4.0 // heuristic for now. can take into account screen size to get min dist for scene y units
        zx = Math.max(MIN_ZOOM, zx)
        let zz = minimap_camera.top / scene_h_height // zoom to make top and bottom line up
        let zoom = Math.min(zx, zz)
        minimap_camera.zoom = zoom

        // position
        let cx = boundingBox.min.x + scene_h_width
        let cz = boundingBox.min.z + scene_h_height

        const minimap_h_width = minimap_camera.right / zoom;
        let minimap_background_left = cx - minimap_h_width // in local coords, where does left of minimap background cut off, on initial load
        let minimap_background_right = cx + minimap_h_width // in local coords, where does left of minimap background cut off, on initial load

        // const minimap_h_height = minimap_camera.top / zoom;
        // let base_height_proportion = scene_h_height / minimap_h_height
        // console.log("base height proportion", base_height_proportion.toFixed(2))

        // shift minimap background. relevent for long models. should shift / scroll like vscode minimap
        let scene_max_x = boundingBox.max.x
        let shift_to_align_right = scene_max_x - minimap_background_right + pad_x // NOTE why do i need to put padding here too? why not just in bounding box?
        let shift_to_align_left = -minimap_background_left - pad_x

        let main_camera_x = camera.position.x // main camera
        let main_camera_h_width = camera.right / camera.zoom
        let b = main_camera_h_width * 1 //1.5 // scalar gives buffer, at 1.0 will align perfectly, but nicer to have buffer
        let minimap_background_shift = utils.interp(main_camera_x, [b, scene_max_x-b], 
                                                                      [shift_to_align_left, shift_to_align_right])
        
        if (minimap_background_left>0) { // if minimap fits entirely within width, no need to scroll widthwise
          cx = cx + minimap_background_shift

          let scrollbar_width_perc = ((minimap_h_width*2) / scene_max_x)*100
          // scrollbar_width_perc = parseInt(scrollbar_width_perc)
          let scrollbar_left = utils.interp((main_camera_x-main_camera_h_width), [0, scene_max_x-main_camera_h_width*2], [0, (100-scrollbar_width_perc)])
          // scrollbar_left = parseInt(scrollbar_left)

          setMinimapScrollbarPos({
            'left_perc':scrollbar_left,
            'width_perc':scrollbar_width_perc,
            'display':'block',
            'minimap_height':(minimap_total_height-minimap_scrollbar_height)
          })
        } else {
          setMinimapScrollbarPos({
            'left_perc':0,
            'width_perc':0,
            'display':'none',
            'minimap_height':minimap_total_height
          })
        }


        // set
        minimap_camera.position.set(cx, MINIMAP_CAMERA_HEIGHT, cz);
        minimap_camera.lookAt(cx, 0, cz); //

        minimap_camera.updateProjectionMatrix(); // otherwise zoom doesn't update, though position does

        minimap_renderer.render(scene, minimap_camera);
    }

    function update_minimap_window_from_main_window() {
      let [h_width, h_height, cx, cz] = utils.get_main_window_position()
      minimap_window.scale.x = h_width*2; minimap_window.scale.y = h_height*2; minimap_window.scale.z = 1
      minimap_window.position.x = cx; minimap_window.position.z = cz; 
      minimap_window.position.y = MAIN_CAMERA_HEIGHT + 1 // slightly higher than main camera. Can also just make not visible to main camera  
    }

    function update_main_window_from_minimap_window() {
      let cx = minimap_window.position.x 
      let cz = minimap_window.position.z
      update_main_camera_position(cx, cz)
    }


    
    ///////////////////////////
    // Start animation loop
    animate();
    function animate(time) {
        stats.begin();
        let camera = globals.camera

        let camera_moved_or_zoomed = (camera_pos_x != camera.position.x) || (camera_pos_y != camera.position.y) || (camera_zoom != camera.zoom)
        let camera_zoom_changed = (camera_zoom !== camera.zoom)

        // this slows us down substantially during dragging, which is when we most need perf
        // minimap
        if (globals.nn) {
          if (minimap_window_is_dragging) {
            update_main_window_from_minimap_window()
            render_minimap()
          } else {
            if (camera_moved_or_zoomed || globals.is_tweening) {
              update_minimap_window_from_main_window()
              render_minimap()
            }
          }
        }

        TWEEN.update(time);
        controls.update();
        renderer.render( scene, camera );

        // /////
        // inset_renderer.render( scene, inset_camera );
        // /////


        labelRenderer.render( scene, camera );

        //////

        // tracking if camera moved
        camera_pos_x = camera.position.x
        camera_pos_y = camera.position.y
        camera_zoom = camera.zoom
        
        stats.end()

            //
        // controls.target.x = 100

        requestAnimationFrame( animate );
    }

    // Clean up on unmount
    return () => {
        window.removeEventListener('mousemove', onMouseMove, false);
        // window.removeEventListener('resize', onWindowResize, false);
        while (mount.firstChild) {
          mount.removeChild(mount.firstChild);
        }
        while (minimap_mount.firstChild) {
          minimap_mount.removeChild(minimap_mount.firstChild);
        }
      };
  }, []);

  //////////////////////////////////////////////////
  // onHover, onClick
  //////////////////////////////////////////////////

  // Function to get screen coordinates of a 3D object. Chatgpt
  function getScreenCoordinates(object) {
      const worldPosition = new THREE.Vector3();
      object.getWorldPosition(worldPosition);
      const ndc = worldPosition.project(camera);
      const screenX = (ndc.x + 1) / 2 * mount.clientWidth;
      const screenY = (-ndc.y + 1) / 2 * mount.clientHeight;
      return { clientX: screenX, clientY: screenY };
  }

  function onMouseMove(event) { // onHover events

    currentMouseCoords.x = event.clientX
    currentMouseCoords.y = event.clientY

    if (globals.is_tweening) return;

    //
    raycaster.layers.set(CLICKABLE_LAYER)
    const sidebarWidth = 0 //document.querySelector('.sidebar').offsetWidth;
    // Update the pointer position
    pointer.x = ((event.clientX - sidebarWidth) / (window.innerWidth - sidebarWidth)) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera( pointer, camera );
    const intersects = raycaster.intersectObjects( globals.scene.children, true);
    let is_shift = event.shiftKey

    if ( intersects.length > 0 ) {
        let obj = intersects[0].object
        if (obj.is_actvol_mesh) { // activation volume TODO consolidate these
          clear_highlight_on_prev_intersected()
          setFeatureTooltipObject(null)
          INTERSECTED = obj

          INTERSECTED.orig_scale_x = INTERSECTED.scale.x
          INTERSECTED.orig_scale_y = INTERSECTED.scale.y
          INTERSECTED.scale.x += sphere_extra_on_hover
          INTERSECTED.scale.y += sphere_extra_on_hover
          let op = obj.actual_node
          console.log(op)

          setTooltipPosition({ left: currentMouseCoords.x, //screen_coords.clientX, 
                                top: currentMouseCoords.y-10, //screen_coords.clientY 
                              });
          setTooltipObject(op);
          hovered_op = op
          
          let actvol_help_text = <div>
                                  Activation volume for tensor-{op.tensor_id.slice(4)}. Tensor dimensions are colored by
                                  <span style={{ color: 'blue' }}> position</span>,  
                                  <span style={{ color: 'green' }}> channel</span>, and 
                                  <span style={{ color: 'grey' }}> batch</span>.
                                </div>

          if (op.activations_available) { // if has activations, indicate that we can show them
            let prev_colors = INTERSECTED.material.map(mat => mat.color)
            INTERSECTED.prev_colors = prev_colors
            INTERSECTED.material.forEach(mat => {
              mat.color = new THREE.Color(mat.color.r * 2.8, mat.color.g*1.4, mat.color.b)
            })
            setHelpInformation(
              <div>
                {actvol_help_text}
                <br></br>
                <div>
                  Double-click to expand. Right-click for more options
                </div>
              </div>
              )
          } else {
            setHelpInformation(actvol_help_text)
          }
        } else if (obj.is_actgrid_background) { // background of actgrid
          clear_highlight_on_prev_intersected()
          setFeatureTooltipObject(null)

          INTERSECTED = obj

          INTERSECTED.orig_scale_x = INTERSECTED.scale.x
          INTERSECTED.orig_scale_y = INTERSECTED.scale.y
          INTERSECTED.scale.x += sphere_extra_on_hover
          INTERSECTED.scale.y += sphere_extra_on_hover
          let op = obj.actual_node
          setTooltipPosition({ left: currentMouseCoords.x, //screen_coords.clientX, 
                                top: currentMouseCoords.y-10, //screen_coords.clientY 
                              });
          setTooltipObject(op);
          hovered_op = op
          setHelpInformation(`Double-click to close activations grid. Right-click for more options`)
          
          let prev_color = INTERSECTED.material.color
          INTERSECTED.prev_color = prev_color
          let c = INTERSECTED.material.color
          INTERSECTED.material.color = new THREE.Color(c.r * 2.8, c.g*1.4, c.b) // same formula as above
          
        } else if ("smaller_sphere" in intersects[ 0 ].object) { // mouseover node
            if ( INTERSECTED != intersects[ 0 ].object.smaller_sphere ) {

                clear_highlight_on_prev_intersected()


                if (intersects[ 0 ].object.actual_node.node_type=="module") { // module
                  INTERSECTED = intersects[ 0 ].object.outline_sphere;

                  INTERSECTED.prev_color = utils.node_color_outline //INTERSECTED.material.color // was sometimes getting stuck on highlight color?

                  INTERSECTED.material.color = utils.node_highlight_color 

                  // so react is using this confusing one and i'm tracking hovered op myself. This is for context menu, only get it when module
                  hovered_op = INTERSECTED.actual_node
                  setHelpInformation(`Double-click to expand module. Right-click for more options.`)

                } else { // individual node
                  INTERSECTED = intersects[ 0 ].object.smaller_sphere;

                  INTERSECTED.prev_color = INTERSECTED.material.color 

                  if (INTERSECTED.actual_node.activations_available){ // small tensor square but can show actgrid
                    hovered_op = INTERSECTED.actual_node

                    let prev_color = INTERSECTED.material.color // was sometimes getting stuck on highlight color?
                    INTERSECTED.prev_color = prev_color

                    let c = INTERSECTED.material.color
                    INTERSECTED.material.color = new THREE.Color(c.r * 2.8, c.g*1.4, c.b) // same formula as above
                    setHelpInformation("Dbl-click to expand activations. Right-click for more options.")

                  } else {
                    hovered_op = null
                    setHelpInformation("")
                  }
                }
                
                INTERSECTED.orig_scale_x = INTERSECTED.scale.x
                INTERSECTED.orig_scale_y = INTERSECTED.scale.y

                INTERSECTED.scale.x += sphere_extra_on_hover
                INTERSECTED.scale.y += sphere_extra_on_hover
                console.log("mouseover node", INTERSECTED.actual_node)

                let screen_coords = getScreenCoordinates(INTERSECTED)
                setTooltipPosition({ left: screen_coords.clientX, top: screen_coords.clientY });
                setTooltipObject(INTERSECTED.actual_node);

                console.log(tooltipObject)
            }
        } else if ("expanded_op" in intersects[ 0 ].object) { // mouseover plane
            setTooltipObject(null);
            setFeatureTooltipObject(null)

            if ( INTERSECTED != intersects[ 0 ].object ) {

                clear_highlight_on_prev_intersected()

                INTERSECTED = intersects[ 0 ].object;
                let background_plane = INTERSECTED.expanded_op.expanded_plane_background_mesh
                
                let c = background_plane.material.color
                background_plane.prev_color = c
                background_plane.material.color = utils.plane_highlight_color
                
                background_plane.scale.x += plane_outline_extra_on_hover
                background_plane.scale.y += plane_outline_extra_on_hover

                hovered_op = intersects[ 0 ].object.expanded_op

                setHelpInformation(`Double-click to collapse module "${hovered_op.name}". Right-click for more information`)

                if (is_shift) console.log("mouseover plane", intersects[ 0 ].object.expanded_op)

            }
        } else if (intersects[ 0 ].object.is_channel_mesh) { // channel slice
            clear_highlight_on_prev_intersected()
            
            let channel_mesh = intersects[ 0 ].object
            // clear_highlight_on_prev_intersected()

            const instanceId = intersects[ 0 ].instanceId; // Get the ID of the intersected instance
            // Do something with the intersected instance

            // let channel_ix = channel_mesh.userData.channel_ix
            let channel_ix = instanceId
            let tensor_id = channel_mesh.userData.tensor_id
            let featurespace = globals.featurespace[tensor_id]

            // setHelpInformation(`showing strongest dataset activations on Imagenet val for tensor ${tensor_id.slice(4)}-channel ${channel_ix}`)
            setHelpInformation(
            <div>
              Full activations for tensor {tensor_id.slice(4)}. Activations are normalized
              by dataset mean and std. Tooltip is currently showing dataset exemplars for channel {channel_ix}. 
            </div>
            )

            let mean = 0 //featurespace["means"][channel_ix]
            let std = 0 //featurespace["stds"][channel_ix]
            let top_5 = featurespace["top_5s"][channel_ix]
            let bottom_5 = featurespace["bottom_5s"][channel_ix]

            let BATCH_IX = 0
            let observation_value = 0 //globals.tensor_trace[tensor_id]["channel_means"][BATCH_IX][channel_ix]

            // console.log(globals.nn.tensor_stats[tensor_id]["full_actvol"][BATCH_IX])
            let z_score = 0 //((observation_value - mean) / std).toFixed(2)

            let T = 1
            // if (Math.abs(z_score)>T) {
            let is_channel_slice = true

            hovered_op = {
              is_channel_slice, tensor_id, channel_ix, mean, std
            }
            if (true) {
                setTooltipPosition({ left: event.clientX, top: event.clientY }); // screen_coords doesn't take into account the slice translations
                let image_paths_pos = top_5.map(ix => {
                  return `/data/imagenet_val/image_${ix}.png`
                })
                let image_paths_neg = bottom_5.map(ix => {
                  return `/data/imagenet_val/image_${ix}.png`
                })
                setFeatureTooltipObject({mean, std, top_5, image_paths_pos, image_paths_neg, observation_value, z_score, channel_ix, tensor_id})
            } else {
              setFeatureTooltipObject(null)
            }

        } else {
            console.log("mouseover unknown something", intersects[0])
        }
    } else { // no selected at all
        clear_highlight_on_prev_intersected()
        INTERSECTED = null;
        setTooltipObject(null);
        setFeatureTooltipObject(null)
        setHelpInformation(utils.base_help_text)
        hovered_op = null
    }
  }
  let plane_outline_extra_on_hover = .14 // TODO should be responsive to current zoom, more constant in screen space
  let sphere_extra_on_hover = .06
  function clear_highlight_on_prev_intersected() {
      if ( INTERSECTED ) { // going from one selected to another
          if ("expanded_op" in INTERSECTED) { // plane
              let background_plane = INTERSECTED.expanded_op.expanded_plane_background_mesh
              if (background_plane){
                background_plane.material.color = background_plane.prev_color
                background_plane.scale.x = background_plane.orig_scale_x
                background_plane.scale.y = background_plane.orig_scale_y
              }
          } else { // node
              if (INTERSECTED.prev_color) {
                INTERSECTED.material.color = INTERSECTED.prev_color;
              } else if (INTERSECTED.prev_colors) { // actvol boxes have separate material for each side
                INTERSECTED.material.forEach((mat,i)=>{
                  mat.color = INTERSECTED.prev_colors[i]
                })
              }
              INTERSECTED.scale.x = INTERSECTED.orig_scale_x
              INTERSECTED.scale.y = INTERSECTED.orig_scale_y
          }
      }
  }

  function onPointerDown( event ) { // dbl-click
    console.log("Doubleclick")
    raycaster.layers.set(CLICKABLE_LAYER)

    // Update the pointer position
    pointer.x = ((event.clientX) / (window.innerWidth)) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    const intersects = raycaster.intersectObjects( globals.scene.children, true);

    let is_shift = event.shiftKey
    if (is_shift) console.log("shift is down")

    if ( intersects.length > 0 ) {
        let obj = intersects[0].object
        let op = obj?.actual_node
        if (op && obj.is_actgrid_background) { // fold actgrid back into volume or square
          console.log("folding actgrid back up")
          op.should_be_actgrid = false
          simple_remake_sequence()
          setActsDisplayStatus('') // unhighlight any btn here bc we've gone custom

        } else if (op && op.activations_available) { // unfold actvol or tensor square into act grid
            op.should_be_actgrid = true
            simple_remake_sequence()
            setActsDisplayStatus('') // unhighlight any btn here bc we've gone custom

        } else if (op && op.node_type==="module") { // expanding an op
            expand_op(op)
            setDropdownValue('') // we've gone custom, null out the depth dropdown
        } else if ("expanded_op" in intersects[0].object) { // collapsing an op TODO clean this up. call it actual node
            let intersect = intersects[0].object
            console.log("attempting to collapse plane for ", intersect.expanded_op.name, intersect.expanded_op)
            if (is_shift) {
              collapse_all_of_mod_class(intersect.expanded_op.name)
            } else {
              collapse_op(intersect.expanded_op)
            }
            setDropdownValue('') // we've gone custom, null out the depth dropdown
        }
    } else { // background, other
    }

    renderer.render(scene, camera); // necessary?
  }

  function expand_op(op){
    op.collapsed = false
    // _expand_op_and_children(op)
    utils.mark_attr(op, "originating_position", {x:op.x, y:0, z:op.y})
    recompute_layout()
    draw_nn()
    utils.mark_attr(op, "originating_position", undefined)
  }

  function expand_all_mods_of_class(mod_class) {
    let to_expand_container = [] // will be populated w ops to collapse
    utils.mark_all_mods_of_family_as_expanded(globals.nn, mod_class, to_expand_container)
    to_expand_container.forEach(o => utils.mark_attr(o, "originating_position", {x:o.x, y:0, z:o.y}))

    recompute_layout() // recompute datastructure
    draw_nn()

    to_expand_container.forEach(o => utils.mark_attr(o, "originating_position", undefined))
  }

  // collapse single expanded plane into node
  function collapse_op(op) {
      op.collapsed = true
      recompute_layout() // recompute datastructure

      // 'terminating_position' is the location of the plane after it has collapsed
      // for a single plane, this won't change before or after compute_layout
      utils.mark_attr(op, "terminating_position", {x:op.x, y:0, z:op.y}) // they will collapse to the top left corner, which is the expanded plane coords

      op.is_in_process_of_collapsing = true // use to tween in the new node

      utils.remove_all_meshes(op, {x:op.x, y:0, z:op.y}) // remove the physical meshes
      draw_nn()
      utils.mark_attr(op, "terminating_position", undefined)
      delete op.is_in_process_of_collapsing
  }

  // collapse all planes of class into nodes
  function collapse_all_of_mod_class(mod_class) {
      
      let ops_to_collapse = [] // will be populated w ops to collapse
      utils.mark_all_mods_of_family_as_collapsed(globals.nn, mod_class, ops_to_collapse)
      ops_to_collapse.forEach(o => utils.mark_attr(o, "plane_was_at_this_position", {x:o.x, y:0, z:o.y}))

      recompute_layout() // recompute datastructure

      ops_to_collapse.forEach(o => {
        utils.mark_attr(o, "terminating_position", {x:o.x, y:0, z:o.y}) // for multiple planes simultaneously, has to be done after recompute_layout
        o.is_in_process_of_collapsing = true
        utils.remove_all_meshes(o, {x:o.x, y:0, z:o.y}) // remove the physical meshes
      })
      draw_nn()

      // cleanup
      ops_to_collapse.forEach(o => {
        utils.mark_attr(o, "terminating_position", undefined)
        delete o.is_in_process_of_collapsing
      })
      ops_to_collapse.forEach(o => utils.mark_attr(o, "plane_was_at_this_position", undefined))
  }
 

  ////////////////////////////////////
  // On change settings, update appropriately
  ////////////////////////////////////

  useEffect(() => {
    if (filters.selectedModelPath) { // change the model
        // Load new nn
        console.log("loading nn", filters.selectedModelPath)
        utils.clear_scene()

        globals.curves_lookup = {} // have to reset this so as to not track. All curves have already been removed from scene above

        label_utils.populate_labels_pool()

        
        ///////////////
        // model arch graph
        fetch(filters.selectedModelPath)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {
                // decompress gzip
                const uint8Array = new Uint8Array(arrayBuffer);
                const decompressed = pako.ungzip(uint8Array, { to: 'string' });
                const _nn = JSON.parse(decompressed);
                
                setActsDisplayStatus('volumes') // should have default value in globals

                globals.nn = _nn
                utils.load_trace_if_possible() // get toc, if has featurespace and tensor traces, get those

                utils.add_tensor_specs() // prep for making actvols and actgrids

                //////////////////////////
                // Nn is loaded. Now do initial processing
                // much of this could be done beforehand in python
                // set global nn data
                console.log("loaded nn", globals.nn)
                let nn = globals.nn

                function copy_dims(op) {
                    op.x_relative_original = op.x_relative
                    op.y_relative_original = 0 //op.y_relative no longer capturing this, just row order BUG REPORT this took 1.5 hrs to find this: there were nans here so in the base row case y_relative was never being marked! nans were only breaking things on depth change. After removing y_relative from our python backend, i should have been more careful to test everything
                    op.children.forEach(c => copy_dims(c))
                }
                copy_dims(nn)

                // mark parentage, convenience
                function mark_parentage(op) {
                  op.children.forEach(c => {
                    c.parent_op = op
                    mark_parentage(c)
                  })
                }
                mark_parentage(nn)

                globals.nodes_lookup = {}
                function add_to_nodes_lookup(op) { // modules and ops
                  globals.nodes_lookup[op["node_id"]] = op
                  op.children.forEach(c => add_to_nodes_lookup(c))
                }
                add_to_nodes_lookup(nn)

                //
                globals.modules_lookup_by_identifier = {}
                function add_to_mods_lookup(op) { // modules and ops
                  if (op.node_type=="module") {
                    if (op.mod_identifier in globals.modules_lookup_by_identifier) {
                      console.log("duplicate mod identifier already in lookup? XXXXXXXXXXXXXXXXXXXXX shouldn't happen", op.mod_identifier)
                      console.log("node_ids", globals.modules_lookup_by_identifier[op.mod_identifier]["node_id"], op["node_id"])
                    } // TODO perf
                    globals.modules_lookup_by_identifier[op.mod_identifier] = op
                    op.children.forEach(c => add_to_mods_lookup(c))
                  }
                }
                add_to_mods_lookup(nn)
                //

                // adding actual upstream nodes, for convenience. Not used currently.
                console.time("linking upstream nodes")
                function link_upstream_nodes(op){
                  op.upstream_nodes = op.uns.map(nid => globals.nodes_lookup[nid])
                  op.children.forEach(c => link_upstream_nodes(c))
                }
                link_upstream_nodes(nn)
                console.timeEnd("linking upstream nodes")

                // set max depth, used for scales
                globals.max_depth = 0
                function set_max_depth(op) {
                  globals.max_depth = Math.max(globals.max_depth, (op.depth ? op.depth : 0))
                    if (!op.collapsed){
                        op.children.forEach(c => set_max_depth(c))
                    }
                }
                set_max_depth(nn)
                console.log("max depth ", globals.max_depth)


                //////////////////////////////////////////
                //
                let default_depth = globals.max_depth

                if (nn.default_settings) {
                    console.log("loading saved settings")
                    utils.load_saved_settings(nn, nn.default_settings)
                } else {
                    // Get default depth
                    console.log("no saved settings, calculating defaults")

                    console.time("calc default depth")
                    let depth_counter = {}
                    function count_n_nodes_at_depth_levels(op) {
                        if (!(op.depth in depth_counter)) {
                          depth_counter[op.depth] = 0
                        }
                        depth_counter[op.depth] += op.children.length
                        op.children.forEach(c => count_n_nodes_at_depth_levels(c))
                    }
                    count_n_nodes_at_depth_levels(nn)

                    let max_default_nodes = 3200 //1600
                    let cumulative_nodes_shown = 0
                    for (let depth=0; depth<=globals.max_depth; depth++){
                      let nodes_at_depth = depth_counter[depth]
                      cumulative_nodes_shown += nodes_at_depth
                      if (cumulative_nodes_shown>max_default_nodes) {
                        default_depth = depth-1 // prev 
                        break
                      }
                    }
                    default_depth = Math.max(default_depth, 2)
                    console.log("default depth ", default_depth, "nodes at depths", depth_counter)
                    // init at collapsed depth
                    utils.mark_all_mods_past_depth_as_collapsed(default_depth) // returns, but don't need it now bc no transitions

                    // init w reshape ops collapsed
                    utils.mark_all_mods_of_family_as_collapsed(nn, "reshape*", []) // returns, but don't need it
                    
                }

                /////////////////////////////////////////////


                recompute_layout()
                draw_nn()

                setDropdownValue(default_depth)
                const depth_values = [];
                for (let i = 1; i <= globals.max_depth; i++) { depth_values.push(i) }
                setDepthValues(depth_values)
                console.timeEnd("calc default depth")

                /////

                // minimap window plane
                globals.scene.add(minimap_window)

                // set camera to default

                // zoom
                let pad = 3
                let zz = (camera.top*.6) / (globals.scene_bb.hheight + pad) // first is percentage of height to fill, screen space; second is padding in scene space
                zz = Math.min(30, zz)
                // zoom to make scene fill space vertically, ie so top of scene is aligned w top of viewport and bottom same. 
                // but w padding
                // scene_bb set in recompute_layout

                let CAMERA_DEFAULT_X = 500 / zz // desired screen space coords / zoom == scene space coords
                let CAMERA_DEFAULT_Z = globals.scene_bb.hheight - (20 / zz) //  center vertically, then small shift downward in screen space
                update_main_camera_position(CAMERA_DEFAULT_X, CAMERA_DEFAULT_Z)
                let default_zoom = zz //20
                camera.zoom = default_zoom
                camera.updateProjectionMatrix()

                // draw labels
                label_utils.update_labels()

                //////////////////
                //
                let total_params = 0
                let total_latency = 0
                let max_memory_allocated = 0
                function accumulate_stats(op) {
                  if ('n_params' in op) {
                    total_params += op.n_params 
                  }
                  if ('latency' in op) {
                    if (op.node_type == "function")
                    total_latency += op.latency 
                  }
                  if ('max_memory_allocated' in op) {
                    if (op.node_type == "function")
                    max_memory_allocated = Math.max(max_memory_allocated, op.max_memory_allocated)
                  }
                  op.children.forEach(c => accumulate_stats(c))
                }
                accumulate_stats(nn)
                let overviewStats = {
                  'total_params':total_params,
                  'total_latency':total_latency,
                  'max_memory_allocated':max_memory_allocated
                }
                setOverviewStats(overviewStats)
                console.log(overviewStats)
            })
    // end load new nn
    } else if (filters.dropdownValue) { // collapse model graph to depth
        collapse_to_depth(filters.dropdownValue)
    } else if ("nodesColorBy" in filters) { // node color by: type, n params, latency, memory, etc
      let colorBy = filters.nodesColorBy
      console.log("coloring nodes", colorBy)
      globals.nodes_color_by = colorBy
      utils.update_node_colors()
    } else if ("actsDisplayStatus" in filters) { // activations display type: collapsed into nodes, actvols, or expanded into actgrids
      let actsDisplay = filters.actsDisplayStatus
      if (actsDisplay==="collapsed") { // all as tensor nodes
        globals.SHOW_ACTIVATION_VOLUMES = false
      } else if (actsDisplay==="volumes") { // volumes when possible
        globals.SHOW_ACTIVATION_VOLUMES = true
        globals.ops_of_visible_nodes.forEach(op => { 
          if (op.activations_available) {
            op.should_be_actgrid = false
          }
        })
      } else if (actsDisplay==="expanded") { // actgrids when possible, volumes if not (and possible), tensor nodes as fallback
        globals.SHOW_ACTIVATION_VOLUMES = true
        globals.ops_of_visible_nodes.forEach(op => {
          if (op.activations_available) {
            op.should_be_actgrid = true
          }
        })
      }
      simple_remake_sequence()
    }

  }, [filters])

  ///////////////////////////////////////////
  // right click

  const handleClose = () => {
    setContextMenu(null);
  };
  const handleRightClick = (event) => {
    event.preventDefault(); // don't want the default context menu
    // Not actually using this anymore, doing our own manually w right click. Couldn't get timing down easily enough using this one
    // 
  };
  const contextMenuExpandModule = () => {
    let op = contextMenu.current_op
    expand_op(op)
    handleClose()
    setDropdownValue('') // we've gone custom, null out the depth dropdown
  }
  const contextMenuExpandAllOfClass = () => {
    let op = contextMenu.current_op
    expand_all_mods_of_class(op.name)
    handleClose()
    setDropdownValue('') // we've gone custom, null out the depth dropdown
  }
  const contextMenuCollapseAllOfClass = () => {
    let op = contextMenu.current_op
    collapse_all_of_mod_class(op.name)
    handleClose()
    setDropdownValue('') // we've gone custom, null out the depth dropdown
  }
  const contextMenuCollapseModule = () => {
    let op = contextMenu.current_op
    collapse_op(op)
    handleClose()
    setDropdownValue('') // we've gone custom, null out the depth dropdown
  }
  //
  const contextMenuExpandAllReshapeModules = () => {
    expand_all_mods_of_class("reshape*")
    handleClose()
  }
  const contextMenuCollapseAllReshapeModules = () => {
    collapse_all_of_mod_class("reshape*")
    handleClose()
  }
  //
  const contextMenuDebugModeOn = () => {
    globals.DEBUG = true

    simple_remake_sequence()
    handleClose()
  }
  const contextMenuDebugModeOff = () => {
    globals.DEBUG = false

    simple_remake_sequence()
    handleClose()
  }

  const contextMenuColorByGrads = () => {
    globals.acts_color_by = "grads"
    utils.update_node_colors()
    handleClose()
  }
  const contextMenuColorByActs = () => {
    globals.acts_color_by = "acts"
    utils.update_node_colors()
    handleClose()
  }

  function apply_patch_fn(body) {
      // Send the data to the backend and update on return
      fetch('http://127.0.0.1:5000/apply-patch', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data)
          globals.tensor_trace = data.tensor_stats
          if (data.grads) {
            globals.grads = data.grads
          }
          utils.update_node_colors()
        })
        .catch(error => console.error('Error:', error));
  }

  const contextMenuClearAllPatches = () => {
    let body = {"action": "clear_all_patches"}
    apply_patch_fn(body)
    handleClose()
  }

  const contextMenuMuteChannel = () => {
    console.log("muting channel")

    let tensor_id = contextMenu.current_op.tensor_id
    let channel_ix = contextMenu.current_op.channel_ix
    let value = contextMenu.current_op.mean
    let patch_type = "channel"
    let body = { patch_type, tensor_id, channel_ix, value }

    apply_patch_fn(body)

    // simple_remake_sequence()
    handleClose()
  }
  const contextMenuAmplifyChannel = () => {

    let tensor_id = contextMenu.current_op.tensor_id
    let channel_ix = contextMenu.current_op.channel_ix
    let value = contextMenu.current_op.mean + (contextMenu.current_op.std * 6)
    let patch_type = "channel"
    let body = { patch_type, tensor_id, channel_ix, value }

    apply_patch_fn(body)

    // simple_remake_sequence()
    handleClose()
  }
  const contextMenuNegativeAmplifyChannel = () => {

    let tensor_id = contextMenu.current_op.tensor_id
    let channel_ix = contextMenu.current_op.channel_ix
    let value = contextMenu.current_op.mean + (contextMenu.current_op.std * -6)
    let patch_type = "channel"
    let body = { patch_type, tensor_id, channel_ix, value }

    apply_patch_fn(body)

    // simple_remake_sequence()
    handleClose()
  }
  const contextMenuBackwardsOn = () => {
    console.log("backwards on")

    let tensor_id = contextMenu.current_op.tensor_id
    let channel_ix = contextMenu.current_op.channel_ix
  
    // Send the data to the backend
    fetch('http://127.0.0.1:5000/backwards-on', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tensor_id, channel_ix }),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data)
          globals.grads = data
          globals.acts_color_by = "grads"
          utils.update_node_colors()
        })
        .catch(error => console.error('Error:', error));


    // simple_remake_sequence()
    handleClose()
  }

  const contextMenuCloseActGrid = () => {
    contextMenu.current_op.should_be_actgrid = false
    simple_remake_sequence()
    handleClose()
    setActsDisplayStatus('') // unhighlight any btn here bc we've gone custom
  }
  const contextMenuExpandActGrid = () => {
    contextMenu.current_op.should_be_actgrid = true
    simple_remake_sequence()
    handleClose()
    setActsDisplayStatus('') // unhighlight any btn here bc we've gone custom
  }
  function simple_remake_sequence(){
    // convenience, for when we don't need to do the separate parts individually
    recompute_layout()
    draw_nn()
    label_utils.update_labels()
  }

  const render_menu_items = () => {
    if (contextMenu === null) return [<MenuItem></MenuItem>]

    if (contextMenu.current_op) {
        let op = contextMenu.current_op

        if (op.is_channel_slice) { // channel slice. TODO awkward, this isn't an op, we should generalize hovered_op / current_op to "hovered thing" or similar
          return [
              <MenuItem onClick={contextMenuMuteChannel}>Mute tensor {op.tensor_id}-{op.channel_ix}</MenuItem>,
              <MenuItem onClick={contextMenuAmplifyChannel}>amplify tensor {op.tensor_id}-{op.channel_ix}</MenuItem>,
              <MenuItem onClick={contextMenuNegativeAmplifyChannel}>negative amplify tensor {op.tensor_id}-{op.channel_ix}</MenuItem>,
              <MenuItem onClick={contextMenuBackwardsOn}>Backwards on {op.tensor_id}-{op.channel_ix}</MenuItem>,
          ]
        } else { // actually an op
          if (op.collapsed && op.node_type==="module") { // collapsed module
              return [
                <MenuItem onClick={contextMenuExpandModule}>Expand this module "{op.name}"</MenuItem>,
                <MenuItem onClick={contextMenuExpandAllOfClass}>
                  Expand all modules of class "{op.name}"
                </MenuItem>,
            ]
          } else if (!op.collapsed) { // expanded plane
              return [
                <MenuItem onClick={contextMenuCollapseModule}>Collapse this module "{op.name}"</MenuItem>,
                <MenuItem onClick={contextMenuCollapseAllOfClass}>
                  Collapse all modules of class "{op.name}"
                </MenuItem>,
            ]
          } else if (op.activations_available) { // activations to show if we want
            if (op.tensor_node_display_type==="grid") { // actgrid
              return [
                <MenuItem onClick={contextMenuCloseActGrid}>Hide activations</MenuItem>,
              ]
            } else { // volume or square
              return [
                  <MenuItem onClick={contextMenuExpandActGrid}>Show activations</MenuItem>,
              ]
            }
          }
        }


    } else { // no current op hovered over, root menu
      return [
        <MenuItem onClick={contextMenuDebugModeOn}>Turn on debug mode</MenuItem>,
        <MenuItem onClick={contextMenuDebugModeOff}>Turn off debug mode</MenuItem>,
        <MenuItem onClick={contextMenuExpandAllReshapeModules}>Expand all reshape ops</MenuItem>,
        <MenuItem onClick={contextMenuCollapseAllReshapeModules}>Collapse all reshape ops</MenuItem>,

        <MenuItem onClick={contextMenuColorByActs}>Color by acts</MenuItem>,
        <MenuItem onClick={contextMenuColorByGrads}>Color by grads</MenuItem>,
        <MenuItem onClick={contextMenuClearAllPatches}>Clear all patches</MenuItem>,

    ]
    }

  }

  const dragDivRef = useRef(null);
  // TODO let back in w microscope. Will need to fix error where can't set listener unless showing input control panel
  // useEffect(() => {
  //   const elmnt = dragDivRef.current;

  //   let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  //   let waiting_for_response = false

  //   function dragMouseDown(e) {
  //     e = e || window.event;
  //     e.preventDefault();
  //     pos3 = e.clientX;
  //     pos4 = e.clientY;
  //     document.onmouseup = closeDragElement;
  //     document.onmousemove = elementDrag;
  //   }

  //   function elementDrag(e) {
  //     e = e || window.event;
  //     e.preventDefault();
  //     pos1 = pos3 - e.clientX;
  //     pos2 = pos4 - e.clientY;
  //     pos3 = e.clientX;
  //     pos4 = e.clientY;
  //     elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
  //     elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  //     // if (!waiting_for_response) {
  //     //   waiting_for_response = true
  //     //   // run_model_w_patch()
  //     // }
  //   }
  //   function run_model_w_patch() {
  //       // Get the position and size of the element
  //       const top = parseInt(elmnt.style.top);
  //       const left = parseInt(elmnt.style.left);
  //       const width = elmnt.offsetWidth;
  //       const height = elmnt.offsetHeight;
  //       let patch_type = "input"
  //       let body = { patch_type, top, left, width, height }
  //       apply_patch_fn(body)
  //   }

  //   function closeDragElement() {
  //     document.onmouseup = null;
  //     document.onmousemove = null;
    
  //     run_model_w_patch()
  //   }

  //   elmnt.onmousedown = dragMouseDown;
  // }, [])


  // input imgs dropdown
  const handleInputImgDropdownChange = (event) => {
    let trace_id = event.target.value
    console.log(trace_id)
    setInputImage(trace_id)
    let model_name = globals.nn.trace_metadata.name

    function onLoad(){
      utils.update_node_colors()
      globals.setIsThinking(false)
    } 
    globals.setIsThinking(true)
    globals.setHelpInformation("loading new trace...")
    utils.load_tensor_trace(model_name, trace_id, onLoad)


    // // if connected to backend
    // let body = { trace_id }

    // fetch('http://127.0.0.1:5000/set-input-image', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(body),
    //   })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('Success:', data)
    //   })
    //   .catch(error => console.error('Error:', error));
  };
  
        

  return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} onContextMenu={handleRightClick}>

            <div style={{ zIndex: 2, width: '100%', height: `${minimap_total_height}px`, backgroundColor:'grey', 
                    position: 'absolute', top:'0px', left:'0px'
                    }}>
              <div style={{ backgroundColor:'white', width: '100%', height: `${minimap_scrollbar_height}px`, 
                            position:'relative', 
                            display: `${minimap_scrollbar_pos.display}`,
                            }}>
                  <div style={{ backgroundColor:'lightgrey', width: `${minimap_scrollbar_pos.width_perc}%`, height:'100%', position:'absolute', 
                                left: `${minimap_scrollbar_pos.left_perc}%`,
                                }}></div>
              </div>
              <div ref={minimapMountRef} style={{ backgroundColor:'lightgrey', width: '100%', height:`${minimap_scrollbar_pos.minimap_height}px`, position:'relative'}}></div>

            </div>


            <div ref={mountRef} style={{ zIndex: 1, width: '100%', flex: 1 }}/>

            <div ref={statsRef} style={{ zIndex: 2}} />

            
            {/* window inset for microscope. there will be multiple of these */}
            {/* <div 
              ref={insetMountRef} 
              style={{
                zIndex: 3,
                position: "fixed",
                bottom: "20px",
                left: "400px",
                backgroundColor: "red",
                height: "100px",
                width: "100px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",  // Subtle shadow for lifted effect
                border: "2px solid rgba(0, 0, 0, 0.9)",     // Light outline
              }} 
            /> */}

            {/* <DraggableWindow 
              title="Window 1" 
              initialPosition={{ x: 50, y: 50 }}
            >
              <p className="text-gray-700">
                This is the content of Window 1. It can contain any React elements or components.
              </p>
              <button className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
                Click me!
              </button>
            </DraggableWindow> */}


            {activationsShowing && (
                <DraggableWindow 
                  title="input image" 
                  initialPosition={{ x: 20, y: 200 }}
                  collapsedIcon={Image}
                  initiallyCollapsed={false}
                >
                  {/* input img */}
                  <div style={{
                    position: "relative",
                  }}>
                    <img src={`/data/trace_imgs/${inputImage}.png`}
                        alt={"some alt text"}
                        style={{ maxWidth: '100%', maxHeight: '100%', pointerEvents: 'none', zIndex: 100 }} />
                    {/* img patch to test img ablations */}
                    {/* <div ref={dragDivRef}
                        draggable="true"
                        style={{
                          backgroundColor: "black",
                          height: "100px",
                          width: "100px",
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          display: 'none', // NOTE bring back in w microscope
                          cursor: 'move',
                        }}>
                    </div> */}
                  </div>
                  {/* dropdown */}
                  <div className="form-control">
                    <select id="dropdown" value={inputImage} onChange={handleInputImgDropdownChange}>
                    {tracedImgsList.map(i => (
                        <option key={i} value={i}>
                        {i}
                        </option>
                    ))}
                    </select>
                  </div>
                </DraggableWindow>
            )}

                      

            <div>
              {tooltipObject && !featureTooltipObject && (
                  <Tooltip
                    open={Boolean(tooltipObject)}
                    title={
                      get_tooltip(tooltipObject)
                    }
                    placement="top"
                    arrow
                    style={{
                      position: 'absolute',
                      left: tooltipPosition.left,
                      top: tooltipPosition.top,
                      pointerEvents: 'none',
                    }}
                  >
                  </Tooltip>

              )}
            </div>
            <div>
            { featureTooltipObject &&
                (<Tooltip
                  open={Boolean(featureTooltipObject)}
                  title={
                    <Card style={{width:`${100*10+95+70}px`}}>
                      <CardContent>
                        <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2 }}>
                          {`tensor ${featureTooltipObject.tensor_id.slice(4)} — channel ${featureTooltipObject.channel_ix}`}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
                          <p style={{ width:'70px', fontSize:'12px' }}>
                            strongest <span style={{ color: 'red' }}>positive</span> activations
                          </p>
                            {featureTooltipObject.image_paths_pos.map((src, index) => (
                            <img src={src} alt={`Positive Image ${index}`} key={index} style={{ maxHeight: '100px' }}/>
                          ))}
                        </Box>
                        <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', my: 2 }} /> {/* Horizontal line */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <p style={{ width:'70px', fontSize:'12px'}}>
                            strongest <span style={{ color: 'blue' }}>negative</span> activations
                          </p>
                          {featureTooltipObject.image_paths_neg.map((src, index) => (
                            <img src={src} alt={`Negative Image ${index}`} key={index} style={{ maxHeight: '100px' }}/>
                          ))}
                        </Box>
                      </CardContent>
                    </Card>
                  }
                  slotProps={{
                    popper: {
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          maxWidth: '2048px',
                        },
                      },
                    },
                  }}
                  placement="top"
                  arrow
                  style={{
                    position: 'absolute',
                    left: tooltipPosition.left,
                    top: tooltipPosition.top,
                    pointerEvents: 'none',
                    width:"auto",
                  }}
                >
                </Tooltip>)
            }
          </div>


          <Menu
            keepMounted
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          > 
            {render_menu_items().map((menuItem, index) => // bc not adding key prop in our fn above, adding programatically here
              React.cloneElement(menuItem, { key: `menu-item-${index}` })
            )}
          </Menu>



          </div>;
};



export default MainPanel;


function collapse_to_depth(level) {

  let [ops_to_collapse, ops_to_expand] = utils.mark_all_mods_past_depth_as_collapsed(level)
  
  // keep these collapsed usually
  if (globals.COLLAPSE_ALL_RESHAPE_MODULES) {
    utils.mark_all_mods_of_family_as_collapsed(globals.nn, "reshape*", []) // returns, but don't need it
  }

  ops_to_expand.forEach(o => utils.mark_attr(o, "originating_position", {x:o.x, y:0, z:o.y}))
  ops_to_collapse.forEach(o => utils.mark_attr(o, "plane_was_at_this_position", {x:o.x, y:0, z:o.y}))

  recompute_layout() // recompute datastructure

  ops_to_collapse.forEach(o => {
    utils.mark_attr(o, "terminating_position", {x:o.x, y:0, z:o.y}) // for multiple planes simultaneously, has to be done after recompute_layout
    o.is_in_process_of_collapsing = true
    utils.remove_all_meshes(o, {x:o.x, y:0, z:o.y}) // remove the physical meshes
  })
  draw_nn()
  label_utils.update_labels()

  // cleanup
  ops_to_collapse.forEach(o => {
    utils.mark_attr(o, "terminating_position", undefined)
    delete o.is_in_process_of_collapsing
  })
  ops_to_expand.forEach(o => utils.mark_attr(o, "originating_position", undefined))
  ops_to_collapse.forEach(o => utils.mark_attr(o, "plane_was_at_this_position", undefined))
}

let debug_attrs_list = ['node_id', "dist_from_end_originator_count", "dist_from_end_global", "respath_dist", 
    "dist_from_start_originator_count", "dist_from_start_global",
    "row_counter", "draw_order_row",
    "mod_outputs", "input_group_ix", "input_group_sub_ix",
    'n_ops', 'depth', 'input_shapes', 'output_shapes', 'is_output_global', 
    "sparkflow", "params", "incremental_memory_usage", "max_memory_allocated", "latency", "n_params", "is_conditioning", 
    "is_conditioning_upstream", "mod_identifier"]

let tensor_node_attrs = [
  "shape",
  "dtype",
  // "numel",
  "element_size",
  "total_size",
  "activations_available",
]

let op_attrs_list = [
  "latency",
  "n_params",
  "incremental_memory_usage",
  "max_memory_allocated",
  // "input_shapes",
  // "output_shapes",
]

let formatting_lookup = {
  "latency":utils.formatLatency,
  "n_params":utils.formatNumParams,
  "incremental_memory_usage":utils.formatMemorySize, // TODO isn't handling negatives. Also note that the values look wrong. 
  "max_memory_allocated":utils.formatMemorySize,
  "total_size":utils.formatMemorySize,
  "element_size":utils.formatMemorySize,
  "shape":formatShape,
  "activations_available":(v)=>(v?v:false) // often undefined
}
function formatShape(shape) {
    return `(${shape.join(", ")})`;
}

function color_dims(op) {
  let shape = op.shape
  let dim_types = op.dim_types
  return (
    <div style={{ fontSize: "22px" }}>
      <span style={{ color: 'white' }}>(</span>
      {
        shape.map((s, index) => {
          let color = label_utils.dim_color_lookup[dim_types[index]]
          return <span key={index} style={{ color: color }}>
                  {String(s)}
                  {index < shape.length - 1 && ", "}
                </span>
        })
      }
      <span style={{ color: 'white' }}>)</span>
    </div>
  );
}

// TODO put in fn_metadata

function get_tooltip_body(op) {
  let attrs_list = op.is_tensor_node ? tensor_node_attrs : op_attrs_list
  if (globals.DEBUG) attrs_list = debug_attrs_list;
  return attrs_list.map((p,i) => {
      let v = op[p]
      if (Object.keys(formatting_lookup).includes(p)) {
        v = formatting_lookup[p](v)
      } 
      v = String(v)
      return <div style={{ fontSize: '14px' }} key={i}>{p}: {v}</div>
  })
}
function get_fn_metadata_body(op) {
  if (op.fn_metadata) {
    return Object.keys(op.fn_metadata).map((p,i) => {
      let v = op.fn_metadata[p]
      if (Object.keys(formatting_lookup).includes(p)) {
        v = formatting_lookup[p](v)
      } 
      v = String(v)
      return <div style={{ fontSize: '14px' }} key={i}>{p}: {v}</div>
  })
  }
}


function get_tooltip_header(op) {
  if (op.is_tensor_node) {
    return "tensor"
  } else {
    return op.name
  }
}

function get_tooltip(op) {
  if (op.is_tensor_node) {
    return <div style={{ lineHeight: '1.5', userSelect: 'none' }}>
              <div style={{ fontSize: '22px', fontWeight: 'bold' }}>{get_tooltip_header(op)}</div>
              {
                // color_dims(op)
              }
              {
                get_tooltip_body(op)
              }
          </div>
  } else {
    return <div style={{ lineHeight: '1.5', userSelect: 'none' }}>
              <div style={{ fontSize: '22px', fontWeight: 'bold' }}>{get_tooltip_header(op)}</div>
              {
                get_fn_metadata_body(op)
              }
              {
                get_tooltip_body(op)
              }
          </div>
  }

}